import axios from 'axios'
import { v4 as uuidv4 } from "uuid"

enum MPCurrencyIDs {
  CLP,
  USD,
}

type MPOrderItem = {
  id: string
  title: string
  unit_price: number
  quantity: number
  currency_id: MPCurrencyIDs
}

type MPOrder = {
  items: MPOrderItem[]
  notification_url?: string
  expires: boolean
  expiration_date_to: Date
}

const BASE_URL = 'https://api.mercadopago.com'
const HEADERS = {
  "Authorization": `Bearer ${process.env.REACT_APP_MERCADO_PAGO_ACCESS_TOKEN}`,
}

const PAYMENT_LINK_EXPIRATION_MS = 24 * 60 * 60 * 1000 - 5 * 60 * 1000

// const NOTIFICATION_URL = process.env.MP_WEBHOOKS_NOTIFICATION_URL || ""
// const webhookParams = new URLSearchParams({
//   webhookToken: process.env.MP_WEBHOOKS_VERIFY_TOKEN || "",
//   live_mode: 'true',
// }).toString()


async function getPaymentLink(amount: number) {
  const POST_checkout = `${BASE_URL}/checkout/preferences`
  const currentDate = new Date()

  const order: MPOrder = {
    items: [
      {
        id: uuidv4(),
        title: 'Andrés Fernanda` ❤️',
        unit_price: amount,
        quantity: 1,
        currency_id: MPCurrencyIDs.CLP,
      },
    ],
    // notification_url: `${NOTIFICATION_URL}?${webhookParams}`,
    expires: true,
    expiration_date_to: new Date(
      currentDate.getTime() + PAYMENT_LINK_EXPIRATION_MS
    ),
  }

  try {
    const {
      data: { init_point: paymentLink },
    } = await axios.post(POST_checkout, order, {
      headers: HEADERS,
    })

    return paymentLink
  } catch (error) {
    console.error(error)
  }
}

export { getPaymentLink }