import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, addDoc, doc } from 'firebase/firestore/lite';
const firebaseConfig = {
    apiKey: "AIzaSyCothkdJ78KPVwuEFcjEA0JPIFZN5cdaM4",
    authDomain: "matri-prezfer.firebaseapp.com",
    projectId: "matri-prezfer",
    storageBucket: "matri-prezfer.appspot.com",
    messagingSenderId: "4923523355",
    appId: "1:4923523355:web:51f83aaf1dce043c9d13be"
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

const COLLECTION = "dedicatoria"

async function getDedicatorias() {
    const dedicatoriasCol = collection(db, COLLECTION);
    const dedicatoriasSnapshot = await getDocs(dedicatoriasCol);
    const dedicatoriasList = dedicatoriasSnapshot.docs.map(doc => doc.data());
    return dedicatoriasList;
}

async function postDedicatoria(content: string) {
    const dedicatoriasCol = collection(db, COLLECTION);
    await addDoc(dedicatoriasCol, {
        content
    });
}

export { getDedicatorias, postDedicatoria }