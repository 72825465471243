import { useState } from "react";
import { getPaymentLink } from "../api/mercadopago";
import { postDedicatoria } from "../api/firestore";
import Layout from "antd/es/layout";
import NamesImg from "../assets/images/names.png";
import FlowerImg from "../assets/images/flor.png";
import { Button, Image, Input, InputNumber, Typography } from "antd";

function Payment() {
  const [amount, setAmount] = useState<number>(1000);
  const [dedicatoria, setDedicatoria] = useState<string>("");
  const [invalidAmount, setInvalidAmount] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  async function onFinish() {
    if (!amount) {
      return setInvalidAmount(true);
    }
    console.log(`Generating payment link for ${amount}...`);
    setLoading(true);
    await postDedicatoria(dedicatoria)
    const paymentLink = await getPaymentLink(amount);
    // setLoading(false);
    window.location.href = paymentLink;
  }

  const onChangeAmount = (value: number | null) => {
    if (value === undefined || value === null) return setInvalidAmount(true);
    if (value === 0) {
      setInvalidAmount(true);
      setAmount(value);
    }
    if (value > 0) {
      setInvalidAmount(false);
      setAmount(value);
    }
  };

  return (
    <Layout className="app-container">
      <div className="image-section">
        <Image
          rootClassName="main-image"
          src={NamesImg}
          preview={false}
        />
      </div>
      <div className="payment-form">
        <div className="payment-form-box">
          <div className="payment-form-items">
            <div>
              <Typography.Text className="input-label">
                Ingresa tu aporte
              </Typography.Text>
              <br />
              <InputNumber
                className="input-amount"
                status={invalidAmount ? "error" : undefined}
                size="large"
                defaultValue={amount}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                }
                parser={(value) => Number(value!.replace(/\$\s?|(\.*)/g, ""))}
                onChange={onChangeAmount}
              />
            </div>
            <div>
              <Typography.Text className="input-label">
                Dedicatoria
              </Typography.Text>
              <br />
              <Input.TextArea
                className="input-text"
                onChange={(e) => setDedicatoria(e.target.value)}
              />
            </div>
            <div className="submit-button-section">
              <Button
                type="primary"
                className="submit-button"
                loading={loading}
                onClick={onFinish}
              >
                Aportar
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="image-section">
        <Image src={FlowerImg} preview={false} style={{ width: "80%" }} />
      </div>
    </Layout>
  );
}

export default Payment;
