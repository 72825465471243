
import { theme } from "antd";
import { ConfigProviderProps } from "antd/es/config-provider";

const antdConfigs: ConfigProviderProps = {
  componentSize: "large",
  theme: {
    algorithm: theme.defaultAlgorithm,
    token: {
      colorPrimary: "#6EC6CA",
      sizeUnit: 4,
      fontSize: 14,
    },
    components: {
      Menu: {
        iconSize: 24,
        iconMarginInlineEnd: 15,
      },
    },
  },
};

export { antdConfigs };
