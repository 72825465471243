import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { ConfigProvider } from "antd";
import { antdConfigs } from "./config/antd";
import Payment from "./pages/payments";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

/**
 * This is the entry point of the application
 */
root.render(
    <ConfigProvider {...antdConfigs}>
        <React.StrictMode>
            <Payment />
        </React.StrictMode>
    </ConfigProvider>
);